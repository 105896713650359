import PropTypes from 'prop-types';
import React from 'react';
import {LogoIcon} from '../Logo';
import {Animation, Wrapper} from './styles';

const Loader = ({width = 100, animation, ...rest}) => {
  return (
    <Wrapper {...rest}>
      <div>
        <Animation animation={animation}>
          <LogoIcon width={width} />
        </Animation>
      </div>
    </Wrapper>
  );
};

Loader.propTypes = {
  width: PropTypes.number,
  animation: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['pulse'])]),
};

export default Loader;
