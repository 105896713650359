import PropTypes from 'prop-types';
import React from 'react';

export const Logo = ({
  color1 = '#6d91cb',
  color2 = '#161616',
  width = 250,
  children,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={72 / (250 / width)}
    viewBox="0 0 250 72"
  >
    <path
      d="M140 13h-12.2l-6.5 16.8c-1.9 5-3.3 8.9-4.2 11.8-.3-1-.7-2.4-1.3-4.3-.8-2.3-1.7-4.8-2.8-7.6L106.4 13H94.2l-4 46.5h11.5l1.5-18.1c.3-3.8.4-7.1.5-9.8.2.7.5 1.5.8 2.4.7 2.2 1.6 4.7 2.6 7.5l6.5 17.4h6.9l6.5-17.4c1.6-4.2 2.9-7.8 4-10.8l.7 10.7 1.7 18.1h11.5l-4.8-46-.1-.5zm35.4 27.7h-9.5l.3-.9c2.2-7.5 3.7-12.9 4.5-16.1.3 1.1.7 2.7 1.3 4.9.8 3.1 1.9 6.9 3.2 11.3l.2.8zm2.3-27.7h-13.8l-15.5 46.5h12l2.7-9.3h15.1l2.8 9.3h12.2l-15.4-46.1-.1-.4zm38.1 18.5c-1.8 5.8-3 10.8-3.9 15-.2-1.4-.7-3.2-1.2-5.6-.7-3-1.6-6.1-2.6-9.4L202.5 13h-12.3l15.4 46.5h12.2L233.5 13h-12.2l-5.5 18.5zm22.8 27.9H250V13h-11.4v46.4z"
      fill={color2}
    />
    <path
      d="M48.7 54.6L47.4 40l-.7-8.8c-1 2.7-2.1 5.8-3.3 9l-5.2 13.9h-5.7l-5-13.9-2.8-8.3c-.1 2-.3 4.7-.5 8l-1.1 14.6h-8.3l3-37.2h8.9l5.1 13.5c.8 2.2 1.7 4.4 2.4 6.6.6 1.6 1 3 1.3 4 1-3.1 2.3-6.7 3.8-10.6l5.2-13.5h8.9L57 54.6h-8.3zM0 71.9h71.9V.1H0v71.8z"
      fill={color1}
    />
    {children}
  </svg>
);

Logo.propTypes = {
  color1: PropTypes.string,
  color2: PropTypes.string,
  width: PropTypes.number,
  children: PropTypes.node,
};

export const LogoIcon = ({color = '#6d91cb', width = 72, children}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={width}
    viewBox="0 0 72 72"
  >
    <path
      d="M48.7 54.6L47.4 40l-.7-8.8c-1 2.7-2.1 5.8-3.3 9l-5.2 13.9h-5.7l-5-13.9-2.8-8.3c-.1 2-.3 4.7-.5 8l-1.1 14.6h-8.3l3-37.2h8.9l5.1 13.5c.8 2.2 1.7 4.4 2.4 6.6.6 1.6 1 3 1.3 4 1-3.1 2.3-6.7 3.8-10.6l5.2-13.5h8.9L57 54.6h-8.3zM0 71.9h71.9V.1H0v71.8z"
      fill={color}
    />
    {children}
  </svg>
);

LogoIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  children: PropTypes.node,
};
