import React from 'react';
import ReactDOM from 'react-dom';
//import reactWastageMonitor from 'react-wastage-monitor';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  /*<React.StrictMode>*/
  <App />,
  /*</React.StrictMode>*/ document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

// Dev stuff
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'production') {
  // hot reloading
  if (module.hot) {
    module.hot.accept();
  }
  // wastage monitor
  //reactWastageMonitor(React, ReactDOM);
}
