import React from 'react';
import styled, {css} from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  font-family: 'Roboto', 'Trebuchet MS', sans-serif;
  > div {
    display: inline-block;
    margin: auto;
    perspective: 1000;
    padding: 1.25rem 0;
  }
`;

export const Animation = styled(({animation, ...rest}) => <div {...rest} />)`
  ${({animation}) => `
    ${
      animation &&
      `animation: appear .6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;`
    }
    ${
      animation === 'pulse' &&
      css`
        svg {
          animation: pulse 3.5s ease-in-out infinite both 2s;
        }
      `
    }
  `}

  @keyframes appear {
    0% {
      transform: translateZ(-1400px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes pulse {
    from {
      transform: scale(1);
      transform-origin: center center;
      animation-timing-function: ease-out;
    }
    10% {
      transform: scale(0.89);
      animation-timing-function: ease-in-out;
    }
    17% {
      transform: scale(0.97);
      animation-timing-function: ease-in-out;
    }
    33% {
      transform: scale(0.9);
      animation-timing-function: ease-in-out;
    }
    45% {
      transform: scale(1);
      animation-timing-function: ease-out;
    }
  }
`;
