import React, {lazy, Suspense} from 'react';
import SplashScreen from './components/SplashScreen';
const Main = lazy(() => import('./components/Main'));

const App = () => (
  <Suspense fallback={<SplashScreen />}>
    <Main />
  </Suspense>
);

export default App;
