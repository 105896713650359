import React from 'react';
import Loader from '../../../Loader';

const width = 100;
const styles = {
  position: 'fixed',
  top: '50%',
  marginTop: `-${width / 1.5}px`,
};

const SplashScreen = () => {
  return <Loader width={width} animation="pulse" style={styles} />;
};

export default SplashScreen;
